<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">
    <Snackbar ref="snackbar" />

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filter" persistent width="650">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326"
        >
          <span class="white--text"> Filter </span>
          <v-spacer></v-spacer>
          <v-icon
            @click="dialog.filter = false"
            color="white"
            :disabled="process.run">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            dense
            flat
            v-model="formFilter.license"
            label="Please Input License Code"
            color="#F05326"
          >
          </v-text-field>
          <!-- @keyup="searchMember" -->
          <v-autocomplete
            :search-input.sync="search_member"
            outlined
            dense
            flat
            v-model="formFilter.member_id"      
            :items="member.list"
            label="Pelase Select Member"
            item-value="id"
            item-text="fullname"
            clearable
            color="#F05326"
            sear
          >
          </v-autocomplete>
          <v-autocomplete
            :search-input.sync="search_phone_number"
            outlined
            dense
            flat
            v-model="formFilter.phone"      
            :items="phone_number.list"
            label="Please Select Mobile Phone"
            item-value="phone"
            item-text="phone"
            clearable
            color="#F05326"
            sear
          >
          </v-autocomplete>
          <v-autocomplete
            label="Please Select Merchant"
            outlined
            dense
            flat
            color="primary"
            no-data-text="please select company first"
            :loading="process.run"
            v-model="formFilter.merchant_id"
            :items="merchant.list"
            item-value="id"
            item-text="name"
            :search-input.sync="search_merchant"
            >
            <!-- @keyup="searchMerchant" -->
            <template v-slot:selection="data">
              <div class="text-capitalize">{{ data.item.name }}</div>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  class="text-capitalize"
                  v-html="data.item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-select
            outlined
            dense
            flat
            v-model="formFilter.is_complete"
            :items="[
              { text: 'Unfinished Class', value: 0 },
              { text: 'Class is Finished', value: 1 },
            ]"
            label="Please Select Class Status"
            color="#F05326"
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            :loading="process.run"
            class="text-capitalize"
            @click="closeFilter('close')"
          >
            Close
          </v-btn>
          <v-btn
            depressed
            class="text-capitalize white--text"
            color="red"
            :loading="process.run"
            @click="closeFilter('reset')"
          >
            Reset
          </v-btn>
          <v-btn
            depressed
            color="#EF6C00"
            class="white--text text-capitalize"
            :loading="process.run"
            @click="fetch()"
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DETAIL -->
    <v-dialog v-model="dialog.detail" persistent width="750" scrollable>
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326"
        >
          <span class="white--text"> Detail Member Redeem </span>
          <v-spacer></v-spacer>
          <v-icon @click="dialog.detail = false" color="white">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text style="height: 500px">
          <v-subheader class="font-weight-bold text-h5">Personal Information</v-subheader>
          <div style="border-bottom: 1px solid #f05326" class="mx-3"></div>
          <v-simple-table class="px-3">
            <template>
              <tbody>
                <tr>
                  <th class="text-second pl-0" width="30%">Full Name</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.member ? detail_member.member.fullname : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Email</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.member ? detail_member.member.email : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Phone Number</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.member ? detail_member.member.phone : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Date of Birth</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.member
                        ? detail_member.member.date_of_birth
                        : "-" | date
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mx-3"></v-divider>

          <v-subheader class="font-weight-bold text-h5 mt-3">
            Program Data
          </v-subheader>
          <div style="border-bottom: 1px solid #f05326" class="mx-3"></div>
          <v-simple-table class="px-3">
            <template>
              <tbody>
                <tr>
                  <th class="text-second pl-0" width="30%">Program Name</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.program ? detail_member.program.title : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">
                    Learning Target Title
                  </th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      detail_member.program
                        ? detail_member.program.learning_targets.studies.title
                        : "-"
                    }}
                  </td>
                </tr>
                <!-- <tr>
                  <th class="text-second pl-0" width="30%">
                    Merchant
                  </th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{
                      Object.keys(detail_member.licenses.merchant).length > 0
                        ? detail_member.licenses.merchant.name
                        : "-"
                    }}
                  </td>
                </tr> -->
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mx-3"></v-divider>

          <v-subheader class="font-weight-bold text-h5 mt-3">Competence</v-subheader>
          <div style="border-bottom: 1px solid #f05326" class="mx-3"></div>
          <v-simple-table class="px-3" v-if="detail_member.program">
            <template>
              <tbody>
                <tr
                  v-for="(item, index) in detail_member.program
                    ? detail_member.program.learning_targets.competence
                    : 0"
                  :key="index">
                  <th class="text-second pl-0" width="30%">{{ item }}</th>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mx-3"></v-divider>

          <v-subheader class="font-weight-bold text-h5 mt-3">Data Score Test</v-subheader>
          <div style="border-bottom: 1px solid #f05326" class="mx-3"></div>
          <v-simple-table class="px-3" v-if="detail_member.results">
            <template>
              <tbody>
                <tr
                  v-for="(item, index) in detail_member.results
                    ? detail_member.results.scores.test
                    : 0"
                  :key="index"
                >
                  <th class="text-second pl-0" width="30%">
                    {{ item.type.replace("_", " ").toUpperCase() }}
                  </th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">{{ item.score }} poin</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mx-3"></v-divider>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG CHANGE MEMBERS -->
    <v-dialog v-model="dialog.change_member" persistent width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326"
        >
          <span class="white--text"> Change Member Redeem </span>
          <v-spacer></v-spacer>
          <v-icon
            @click="
              dialog.change_member = false;
              form.member_id = '';
            "
            color="white">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-form>
            <!-- @keyup="searchMember" -->
            <v-autocomplete
              :search-input.sync="search_change_member"
              clearable
              outlined
              dense
              flat
              no-data-text="Please Select Member"
              :disabled="change_member.by_phone"
              v-model="change_member.by_name"
              :items="itemsMember"
              label="Please Select Member by Name"
              :item-value="'id'"
              :item-text="'fullname'"
              :filter="filterObject"
              color="#F05326"
            >
              <template #selection="{ item }">
                {{ item.fullname }} - {{ item.phone }}
              </template>
              <template #item="{ item }">
                {{ item.fullname }} - {{ item.phone }}
              </template>
            </v-autocomplete>
            <v-autocomplete
              :search-input.sync="search_change_member_phone"
              :disabled="change_member.by_name"
              clearable
              outlined
              dense
              flat
              no-data-text="Please Select Member"
              v-model="change_member.by_phone"
              :items="itemsMemberPhone"
              label="Please Select Member by Phone"
              :item-value="'id'"
              :item-text="'phone'"
              :filter="filterObject"
              color="#F05326"
            >
              <template #selection="{ item }">
                {{ item.fullname }} - {{ item.phone }}
              </template>
              <template #item="{ item }">
                {{ item.fullname }} - {{ item.phone }}
              </template>
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#F05326"
            class="white--text text-capitalize"
            depressed
            @click="changeMemberRedeem"
          >
            Save Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG CHANGE MERCHANT -->
    <v-dialog v-model="dialog.change_merchant" scrollable persistent width="650">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326"
        >
          <span class="white--text"> Change Merchant </span>
          <v-spacer></v-spacer>
          <v-icon
            @click="dialog.change_merchant = false"
            color="white"
            :disabled="process.run">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-autocomplete
                label="Please Select Company"
                outlined
                dense
                flat
                color="primary"
                hide-details="auto"
                no-data-text="no data found"
                :loading="process.run"
                v-model="form.company_id"
                item-value="id"
                item-text="name"
                :items="company.list"
                :search-input.sync="search_company"
                @change="
                  fetchMerchant();
                  "
              >
              <!-- @keyup="searchCompany" -->
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                label="Please Select Merchant"
                outlined
                dense
                flat
                color="primary"
                hide-details="auto"
                no-data-text="please select company first"
                :loading="process.run"
                v-model="form.merchant_id"
                :items="merchant.list"
                item-value="id"
                item-text="name"
                :search-input.sync="search_change_merchant"
                >
                <!-- @keyup="searchMerchant" -->
                <template v-slot:selection="data">
                  <div class="text-capitalize">{{ data.item.name }}</div>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize"
                      v-html="data.item.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            :loading="process.run"
            class="text-capitalize"
            @click="dialog.change_merchant = false"
          >
            Close
          </v-btn>
          <v-btn
            depressed
            color="#F05326"
            class="white--text text-capitalize"
            :loading="process.run"
            @click="changeMerchant()"
          >
            Save Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG CHANGE STATUS CALLBACK -->
    <v-dialog v-model="dialog.change_status_callback" scrollable persistent width="650">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326"
        >
          <span class="white--text"> Change Status Callback </span>
          <v-spacer></v-spacer>
          <v-icon
            @click="dialog.change_status_callback = false"
            color="white"
            :disabled="process.run">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                label="License Code"
                outlined
                dense
                flat
                disabled
                color="primary"
                hide-details="auto"
                :loading="process.run"
                v-model="form.license">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Redeem Code"
                outlined
                dense
                flat
                disabled
                color="primary"
                hide-details="auto"
                :loading="process.run"
                v-model="form.pmo_code">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                label="Please Select Sequence"
                outlined
                dense
                flat
                color="primary"
                hide-details="auto"
                no-data-text="no data found"
                :loading="process.run"
                v-model="form.sequence"
                item-value="value"
                item-text="text"
                :items="sequence">
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            :loading="process.run"
            class="text-capitalize"
            @click="dialog.change_status_callback = false"
          >
            Close
          </v-btn>
          <v-btn
            depressed
            color="#F05326"
            class="white--text text-capitalize"
            :loading="process.run"
            @click="changeStatusCallback()"
          >
            Save Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG EXCHANGE DAN SWAP LICENSE -->
    <v-dialog v-model="dialog.change_license" persistent width="550">
      <v-card  class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326">
          <span class="white--text">{{ license_state.type == 'exchange' ? 'Change License' : 'Swap License' }}</span>
          <v-spacer></v-spacer>
          <v-icon
            @click="
              dialog.change_license = false;
              license_state = {
                type: '',
                original_license: '',
                new_license: '',
              };
            "
            color="white">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-alert type="info" text dense>
            {{ license_state.type == 'exchange' ? 
              'License code exchange can be done when both license codes are redeemed.' :
              'License code changes can be made by exchanging a new license code that has not been redeemed.'
            }}
          </v-alert>
          <v-form>
            <v-text-field
              outlined
              dense
              flat
              readonly
              v-model="license_state.original_license"
              label="Original License"
              color="#F05326">
            </v-text-field>
            <v-text-field
              outlined
              dense
              flat
              v-model="license_state.new_license"
              label="New License"
              color="#F05326">
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#F05326"
            class="white--text text-capitalize"
            depressed
            @click="license_state.type === 'exchange' ? exchangeLicense() : swapLicense()">
            Save Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG CHANGE DATE UK -->
    <v-dialog v-model="dialog.change_date_uk" persistent width="550">
      <v-card  class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326">
          <span class="white--text">Change Date UK</span>
          <v-spacer></v-spacer>
          <v-icon
            @click="resetFormChangeDate"
            color="white">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-autocomplete
              outlined
              multiple
              chips
              deletable-chips
              dense
              flat
              v-model="change.licenses"
              label="Select Licenses"
              placeholder="Start typing to Search"
              color="#F05326"
              :items="licenseItems"
              item-value="text"
              :search-input.sync="searchLicense"
              :loading="searchLicenseLoading"
              hide-no-data
              hide-selected
              @input="searchLicense = ''"
            ></v-autocomplete>
            <v-menu
              ref="menu"
              v-model="dialog.picker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="change.datetime"
                  outlined
                  dense
                  readonly
                  label="Tanggal"
                  v-bind="attrs"
                  color="#F05326"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                color="#F05326"
                v-model="change.datetime"
                @input="dialog.picker = false"
              >
              </v-date-picker>
            </v-menu>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  
            :loading="changeLoading"
            color="#F05326"
            class="text-capitalize"
            depressed
            outlined
            @click="resetFormChangeDate()">
            Batal
          </v-btn>
          <v-btn  
            :loading="changeLoading"
            color="#F05326"
            class="white--text text-capitalize"
            depressed
            @click="handleChangeDateUK">
            Save Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-5">
      <div class="title black--text mb-2">List Member Redeem</div>
      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Search Member Redeem . . ."
              autocomplete="off"
              v-model="searching"
              :readonly="process.run"
              clearable
              color="#F05326"
              @click:clear="searching = ''"
              prepend-inner-icon="mdi-magnify"
            >
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn
              :loading="process.run"
              depressed
              elevation="0"
              color="#F5F5F5"
              class="black--text text-capitalize ml-5"
              @click="dialog.change_date_uk = true"
            >
              Change Date UK
            </v-btn>
            <v-btn
              depressed
              :loading="process.run"
              @click="filter"
              elevation="0"
              color="#F5F5F5"
              class="black--text text-capitalize ml-5"
            >
              Filter by
              <v-icon right>mdi-filter-variant</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody"
        >
          <v-data-table
            class="table-list"
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run || process.run"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText: 'Data per halaman',
              'items-per-page-options': [5, 10, 20, 50, 100, 200, 350],
            }"
          >
            <template slot="footer" slot-scope="{ props }">
              <Pagination
                @reload="fetch(page)"
                @changeLimit="
                  limit = $event;
                  fetch();
                "
                @changePage="
                  page = $event;
                  fetch($event);
                "
                :model="props"
              />
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-start white">
                  {{ props.item.license }}
                </td>
                <td class="text-center white">
                  {{ props.item.meta != null && Object.keys(props.item.meta).length > 0 && props.item.meta.pmo_voucher  ? props.item.meta.pmo_voucher : '-'}}
                </td>
                <td class="text-center white">
                  {{ Object.keys(props.item.licenses.merchant).length > 0 ? props.item.licenses.merchant.name : '-' }}
                </td>
                <td class="text-start white">
                  {{ props.item.member.fullname }}
                </td>
                <td class="text-start white">
                  {{ props.item.member.email }}
                </td>
                <td class="text-center white">
                  {{ props.item.member.phone }}
                </td>
                <td class="text-center white">
                  <v-menu
                    left
                    offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on">
                        mdi-dots-vertical-circle-outline
                      </v-icon>
                    </template>

                    <v-list dense  class="pa-0 rounded-lg">
                      <v-list-item @click="change_redeem(props.item.id)">
                        <v-list-item-icon class="mr-4">
                          <v-icon color="blue">mdi-cog-refresh-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Change Member</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item v-if="checkRedeemCode(props.item)" @click="change_status_callback(props.item)">
                        <v-list-item-icon class="mr-4">
                          <v-icon color="purple">mdi-book-sync-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Change Status Callback</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider v-if="checkRedeemCode(props.item)"></v-divider>
                      <v-list-item @click="change_merchant(props.item)">
                        <v-list-item-icon class="mr-4">
                          <v-icon color="green">mdi-store-edit-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Change Merchant</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="swap_license(props.item)">
                        <v-list-item-icon class="mr-4">
                          <v-icon color="pink">mdi-data-matrix-edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Swap License</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="change_license(props.item)">
                        <v-list-item-icon class="mr-4">
                          <v-icon color="teal">mdi-swap-horizontal-circle-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Change to New License</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="details(props.item)">
                        <v-list-item-icon class="mr-4">
                          <v-icon color="warning">mdi-information-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Detail Member</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="change_redeem(props.item)">
                        <v-icon>mdi-eye-arrow-right-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Lihat Member Redeem</span>
                  </v-tooltip> -->
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import GridTable from "@/components/GridTable";
import Pagination from "@/components/Pagination.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Snackbar from "@/components/Snackbar.vue";
export default {
  components: { GridTable, Pagination, Breadcrumbs, Snackbar },
  data() {
    return {
      // searchMember: "",
      change_member: {},
      search_change_member: "",
      search_change_member_phone: "",
      search_member: "",
      search_phone_number: "",
      search_company: "",
      search_merchant: null,
      search_change_merchant: null,

      title: "List Users",
      limit: 10,
      page: 1,
      selected: {
        pagination: 0,
      },
      headers: [
        { text: "Lisensi", align: "start", value: "license", sortable: false },
        { text: "Redeem Code", align: "center", value: "license", sortable: false },
        { text: "Merchant", align: "center", value: "licenses.merchant.name", sortable: false },
        {
          text: "User Full Name",
          align: "start",
          value: "member.fullname",
          sortable: false,
        },
        {
          text: "Email",
          align: "start",
          value: "member.email",
          sortable: false,
        },
        {
          text: "Phone Number",
          align: "center",
          value: "member.phone",
          sortable: false,
        },
        { text: "Actions", align: "center", value: "action", sortable: false },
      ],
      sequence: [
        { value: 1, text: "Untuk redeem/kehadiran pertama"}, // 0
        { value: 2, text: "Kehadiran ke-2" }, // 1
        { value: 3, text: "Kehadiran ke-3" }, // 2
        { value: 4, text: "Kehadiran ke-4" }, // 3
        { value: 5, text: "Kehadiran ke-5" }, // 4
        { value: 6, text: "Kehadiran ke-6" }, // 5
        { value: 7, text: "Kehadiran ke-7" }, // 6
        { value: 8, text: "Kehadiran ke-8" }, // 7
        { value: 9, text: "Kehadiran ke-9" }, // 8
        { value: 10, text: "Kehadiran ke-10" }, // 9
        { value: 999, text: "Post Test" }, // 10
      ],
      items: {},
      phone_number: {},
      member: {},
      detail_member: {},
      member_redeem: {},
      searching: "",
      process: {
        run: false,
      },
      formFilter: {
        member_id: "",
        merchant_id: "",
        license: "",
        is_complete: "",
        program_id: "",
      },
      license_state: {
        type: "",
        original_license: "",
        new_license: "",
      },
      dialog: {
        picker: false,
        filter: false,
        detail: false,
        change_member: false,
        change_license: false,
        change_merchant: false,
        change_status_callback: false,
        change_date_uk: false,
      },
      member: {},
      company: {},
      merchant: {},
      itemsMember: [],
      itemsMemberPhone: [],
      form: {
        id: "",
        license: "",
        pmo_code: "",
        sequence: "",
        member_id: "",
        company_id: "",
        merchant_id: "",
      },
      change: {
        licenses: [],
        datetime: ''
      },
      searchLicense:'',
      searchLicenseLoading: false,
      licenseItems: [],
      searchLicenseTimeout: null,
      changeLoading: false,
      searchMemberTimeout: null
      ,searchMemberQuery: '',
    };
  },
  watch: {
    searchMemberQuery(val) {
      this.selectMemberChanged()
    },
    searchLicense(val) {
      clearTimeout(this.searchLicenseTimeout)
      this.searchLicenseTimeout = null
      this.searchLicenseTimeout = setTimeout(() => {
        this.getLicenseItem(val)
      }, 3e2);
    },
    "selected.pagination": function (val) {
      this.fetch(val + 1);
    },
    searching: _.debounce(function (newVal) {
      this.searching = newVal;
      this.fetch();
    }, 3e2),
    search_change_member: _.debounce(function (newVal) {
      this.search_change_member = newVal;
      this.memberList();
    }, 3e2),
    search_change_member_phone: _.debounce(function (newVal) {
      this.memberPhoneList();
    }, 3e2),
    search_member: _.debounce(function (newVal) {
      this.search_member = newVal;
      this.memberList();
    }, 3e2),
    search_phone_number: _.debounce(function (newVal) {
      this.phoneNumberList();
    }, 3e2),
    search_change_merchant: _.debounce(function (newVal) {
      this.search_change_merchant = newVal;
      this.fetchMerchant()
    }, 3e2),
    search_merchant: _.debounce(function (newVal) {
      this.search_merchant = newVal;
      this.fetchMerchant()
    }, 3e2),
    search_company: _.debounce(function (newVal) {
      this.search_company = newVal;
      this.fetchCompany()
    }, 3e2),
  },
  computed: {
    checkRedeemCode() {
      return (item) => {
        return item.meta != null && Object.keys(item.meta).length > 0 && item.meta.pmo_voucher
      }
    }
  },
  created() {},
  mounted() {
    this.fetch();
    this.getLicenseItem()
  },
  methods: {
    selectMemberChanged() {
      clearTimeout(this.searchMemberTimeout)
      this.searchMemberTimeout = null
      this.searchMemberTimeout = setTimeout(() => {
        this.memberList()
      }, 3e2);
    },
    resetFormChangeDate() {
      this.dialog.change_date_uk = false
      this.change.licenses = []
      this.change.datetime = ''
    },
    filterObject(item, queryText, itemText) {
      return (
        item.fullname
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.phone.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    async filter() {
      await this.memberList();
      await this.fetchMerchant();
      this.dialog.filter = true;
      // this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    closeFilter(state) {
      this.dialog.filter = false;
      if (state != "close") {
        this.formFilter = {
          member_id: "",
          merchant_id: "",
          license: "",
          is_complete: "",
          program_id: "",
          phone: ""
        };
        this.fetch();
      }
    },
    async fetch(paging) {
      let _self = this;
      _self.process.run = true;

      await get(`users/v1/superuser/member_redeem/list`, {
        params: {
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
          member_id: this.formFilter.member_id,
          merchant_id: this.formFilter.merchant_id,
          license: this.searching || this.formFilter.license,
          is_complete: this.formFilter.is_complete,
          member_phone: this.formFilter.phone,
        },
      }).then((response) => {
        let res = response.data;
        this.items = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
        this.totalData = res.data.total;
        this.closeFilter("close");
      });
    },
    async getLicenseItem(search) {
      this.searchLicenseLoading = true
      await get(`users/v1/superuser/member_redeem/list`, {
        params: {
          limit: 20,
          page: 1,
          sort: "created_at",
          dir: "asc",
          license: search,
        },
      }).then((response) => {
        const res = response.data;
        const items = res.data.list.map(v => ({text: v.license}))
        items.push(...this.change.licenses.map(v => ({text: v})))
        this.licenseItems = items
      }).finally(() => {
        this.searchLicenseLoading = false
      });
    },
    handleChangeDateUK() {
      this.changeLoading = true
      post('users/v1/superuser/reset-deadline-uk', {
        data: this.change,
      }).then(() => {
        this.dialog.change_date_uk = false
        this.change.datetime = ''
        this.change.licenses = []
        this.$refs.snackbar.open("#4CAF50", `Successfully Change License`);
        this.fetch();
      }).finally(() => {
        this.changeLoading = false
      })
    },
    details(item) {
      this.detail_member = item;
      this.dialog.detail = true;
    },

    async fetchMerchant() {
      let search = this.dialog.change_merchant
        ? this.search_change_merchant
        : this.search_merchant;
      this.process.run = true;
      await get(
        `merchant/v1/superuser/list`,
        {
          params: {
            limit: 20,
            q: search,
          },
        }
      ).then((response) => {
        let res = response.data;
        this.merchant = res.data;
        this.process.run = false;
      });
    },

    async phoneNumberList() {
      // const search = this.search_member
      await get(`users/v1/superuser/member`, {
        params: {
          limit: 10,
          sort: "created_at",
          dir: "asc",
          phone: this.search_phone_number,
        },
      }).then((response) => {
        let res = response.data;
        this.phone_number = res.data;
      });
    },
    async memberList() {
      // const search = this.search_member
      let search = this.dialog.change_member
        ? this.search_change_member
        : this.search_member;
      await get(`users/v1/superuser/member`, {
        params: {
          limit: 10,
          sort: "created_at",
          dir: "asc",
          fullname: search,
        },
      }).then((response) => {
        let res = response.data;
        this.member = res.data;
        this.itemsMember = res.data.list;
        this.totalData = res.data.total_data;
      });
    },
    async memberPhoneList() {
      await get(`users/v1/superuser/member`, {
        params: {
          limit: 10,
          sort: "created_at",
          dir: "asc",
          phone: this.search_change_member_phone
        },
      }).then((response) => {
        let res = response.data;
        this.itemsMemberPhone = res.data.list;
      });
    },

    // LICENSE CHANGE
    change_license(item) {
      this.license_state = {
        type: "exchange",
        original_license: item.license,
        new_license: "",
      };
      this.dialog.change_license = true;
    },

    exchangeLicense() {
      let _self = this;
      _self.process.run = true;
      put(`users/v1/superuser/member_redeem/exchange_license`, {
        data: {
          origin_license: this.license_state.original_license,
          destination_license: this.license_state.new_license,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Successfully Change License`);
          this.dialog.change_license = false;
          this.license_state = {
            type: "",
            original_license: "",
            new_license: "",
          };
          this.searching = ""
          this.formFilter = {
            member_id: "",
            merchant_id: "",
            license: "",
            is_complete: "",
            program_id: "",
          };
          this.fetch();
        } else {
          this.$refs.snackbar.open("#D32F2F", `Failed to Change License`);
        }
        this.process.run = false;
      }).catch((error) => {
        this.$refs.snackbar.open("#D32F2F", `Failed to Change License`);
        this.process.run = false;
      });
    },
    
    // LICENSE SWAP
    swap_license(item) {
      this.license_state = {
        type: "swap",
        original_license: item.license,
        new_license: "",
      };
      this.dialog.change_license = true;
    },

    swapLicense() {
      let _self = this;
      _self.process.run = true;
      put(`users/v1/superuser/member_redeem/swipe_license/${this.license_state.original_license}`, {
        data: {
          new_license: this.license_state.new_license,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Successfully Swap License`);
          this.dialog.change_license = false;
          this.license_state = {
            type: "",
            original_license: "",
            new_license: "",
          };
          this.searching = ""
          this.formFilter = {
            member_id: "",
            merchant_id: "",
            license: "",
            is_complete: "",
            program_id: "",
          };
          this.fetch();
        } else {
          this.$refs.snackbar.open("#D32F2F", `Failed to Swap License`);
        }
        this.process.run = false;
      }).catch((error) => {
        this.$refs.snackbar.open("#D32F2F", `Failed to Swap License`);
        this.process.run = false;
      });
    },

    // MERCHANT CHANGE
    async change_merchant(item) {
      this.limit = 10;
      this.search_merchant = "";
      this.search_change_merchant = "";
      await this.fetchCompany()
      await this.fetchMerchant();
      this.form = {
        license: item.license,
      };
      this.dialog.change_merchant = true;
    },

    async fetchCompany() {
      this.company = {};
      this.process.run = true;
      await get(`company/v1/superuser/list`, {
        params: {
          limit: 20,
          q: this.search_company,
        },
      }).then((response) => {
        let res = response.data;
        this.company = res.data;
        this.process.run = false;
      });
    },

    async fetchMerchant() {
      let url = "merchant/v1/superuser/list";
      if (this.form.company_id != null) { 
        url = `merchant/v1/superuser/list?company_id=${this.form.company_id}`
      }
      this.process.run = true;
      await get(
        url,
        {
          params: {
            limit: 20,
            q: this.search_merchant,
          },
        }
      ).then((response) => {
        let res = response.data;
        this.merchant = res.data;
        this.process.run = false;
      });
    },

    async changeMerchant () {
      let _self = this;
      _self.process.run = true;
      put(`users/v1/superuser/member_redeem/change_merchant/${this.form.license}`, {
        data: {
          company_id: this.form.company_id,
          merchant_id: this.form.merchant_id,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Successfully Change Merchant`);
          this.dialog.change_merchant = false;
          this.form = {
            company_id: "",
            merchant_id: "",
          };
          this.fetch();
        } else {
          this.$refs.snackbar.open("#D32F2F", `Failed to Change Merchant`);
        }
        this.process.run = false;
      }).catch((error) => {
        this.$refs.snackbar.open("#D32F2F", `Failed to Change Merchant`);
        this.process.run = false;
      });
    },

    // STATUS CALLBACK CHANGE
    change_status_callback(item) {
      this.form = {
        license: item.license,
        pmo_code: item.meta.pmo_voucher,
        sequence: "",
      };
      this.dialog.change_status_callback = true;
    },

    async changeStatusCallback() {
      let _self = this;
      _self.process.run = true;
      put(`users/v1/superuser/status-callback`, {
        data: {
          license: this.form.license,
          pmo_code: this.form.pmo_code,
          sequence: this.form.sequence,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Successfully Change Status Callback`);
          this.dialog.change_status_callback = false;
          this.form = {
            license: "",
            pmo_code: "",
            sequence: "",
          };
          this.fetch();
        } else {
          this.$refs.snackbar.open("#D32F2F", `Failed to Change Status Callback`);
        }
        this.process.run = false;
      }).catch((error) => {
        this.$refs.snackbar.open("#D32F2F", `Failed to Change Status Callback`);
        this.process.run = false;
      });
    },

    // MEMBER REDEEM CHANGE
    async change_redeem(id) {
      // await this.memberList();
      this.itemsMember = [];
      this.dialog.change_member = true;
      this.form.id = id;
    },
    changeMemberRedeem() {
      let _self = this;
      _self.process.run = true;
      put(`users/v1/superuser/member_redeem/change_member`, {
        data: {
          member_redeem_id: this.form.id,
          member_id: this.change_member.by_name || this.change_member.by_phone,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Berhasil Merubah Member Redeem`);
          this.dialog.change_member = false;
          this.form = {
            member_id: "",
          };
          this.change_member = {}
          this.fetch();
        } else {
          this.$refs.snackbar.open("#D32F2F", `Gagal Merubah Member Redeem`);
        }
        this.process.run = false;
      });
    },
  },
};
</script>

<style>
</style>